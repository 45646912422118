<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="md"
      :hide-footer="true"
      :title="product.title"
      lazy
      static
    >
      <b-row class="my-1">
        <b-col v-if="product.variations && product.variations.length" :md="10">
          <span><strong>Variation</strong></span>
        </b-col>
        <b-col :md="2">
          <span><strong>TEMPLE</strong></span>
        </b-col>
      </b-row>
      <template v-if="product.variations && product.variations.length">
        <b-row v-for="(variation, key) of product.variations" :key="key" class="my-1">
          <b-col :md="10">
            <span v-for="(attribute, key) of variation.attributes" :key="key">
              <strong><u>{{ `${attribute.name.charAt(0).toUpperCase()}${attribute.name.slice(1)}:` }}</u></strong> {{ `${attribute.option}` }}
            </span>
          </b-col>
          <b-col :md="2">
            <b-form-input v-model="variation.shop.find(s => s.key === 'TEMPLE').value" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center justify-content-center text-center">
          <b-button
            variant="primary"
            class="mb-1"
            @click="updateValue()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Enregistrer le stock</span>
          </b-button>
        </b-row>
      </template>
      <template v-else-if="product.shop">
        <b-row class="my-1">
          <b-col :md="2">
            <b-form-input v-model="product.shop.find(s => s.key === 'TEMPLE').value" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center justify-content-center text-center">
          <b-button
            variant="primary"
            class="mb-1"
            @click="updateValue(true)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Enregistrer le stock</span>
          </b-button>
        </b-row>
      </template>
    </b-modal>
    <b-row>
      <b-col
        cols="12"
      >
        <div class="mt-1 mb-3">
          <b-row>
            <b-col
              cols="12"
            >
              <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50 text-primary"
                  size="20"
                />
                <b-input-group class="input-group-merge shadow-none">
                  <b-form-input
                    placeholder="Rechercher un produit..."
                    style="height: auto;"
                    class="border-0 shadow-none"
                    @input="filter"
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-card title="Vous pouvez modifier les produits directement ici.">
      <b-row class="match-height">
        <b-col
          v-for="(pdct, keyP) in products"
          :key="keyP"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="pdct.images && pdct.images.length"
              class="item-img text-center"
            >
              <b-img
                :alt="`${pdct.title}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.price }} €
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.title }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(pdct)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier le produit</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    BFormInput,
    BInputGroup,
  },
  data() {
    return {
      baseProducts: [],
      products: [],
      product: {},
      showModal: false,
      options: { mode: 'code' },
      categoryInformations: [],
    }
  },
  async mounted() {
    try {
      const { products } = await this.$http.get('/admin/products', { params: { filter: { country: 'FR' } } })
      this.products = products
      this.baseProducts = products
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async openModal(p) {
      console.log(p)
      this.loading = true
      const { product } = await this.$http.get(`/admin/products/${p._id}`)
      this.product = product.product
      this.product._id = product._id
      this.showModal = true
      console.log(product.product)
    },
    async updateValue(withoutVariations = false) {
      try {
        console.log(this.product)
        if (withoutVariations) {
          await this.$http.put(`admin/products/${this.product._id}/stock`, { shop: this.product.shop, withoutVariations: true })
        } else {
          await this.$http.put(`admin/products/${this.product._id}/stock`, { variations: this.product.variations })
        }
        this.showModal = false
      } catch (err) {
        console.log(err)
      }
    },
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.products = this.baseProducts
      } else {
        const value = e
        this.products = this.baseProducts.filter(product => product.title.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>
